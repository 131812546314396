import axios from "axios";
import { doRefreshToken } from "./api";
import { StorageCookies } from "./storage_cookies";
import { getTokens } from "./getTokens";

export const api = axios.create({
    baseURL: process.env.REACT_APP_GPT_API_URL
});

api.defaults.headers['Authorization'] = `Bearer ${process.env.REACT_APP_API_KEY}`

export const post_message = async ({data, assistant_id}) => {
    console.log(data)
    var path = `/chat`
    var parameters = ``
    if (assistant_id) {
        parameters = `?assistant_id=${assistant_id}`
    }
    return (await api.post(path + parameters, data=data)).data
}

export const post_message_rag = async ({data, assistant_id}) => {
    console.log(data)
    var path = `/chat/retriever`
    var parameters = ``
    if (assistant_id) {
        parameters = `?model=${assistant_id}`
    }
    return (await api.post(path + parameters, data=data)).data
}

export const get_message = async () => {
    return (await api.get(`/chat`)).data
}