import { useEffect, useMemo, useRef, useState } from "react"
import './styles.css'
import Cookies from "js-cookie"
import { getPrevMessages } from "../../../../services/chat_api"
import NewMessage from "./openedChat/newMessage"
import OpenedChat from "./openedChat"

const Chat = ({project, openChat, setOpenChat, currentPage, setCurrentPage}) => {
  
    const handleOpenChat = () => {
        if (project.id === openChat?.id){
            setOpenChat(null)
        }else{
            setOpenChat(project)
        }
    }

    return (
        <>
            <a onClick={handleOpenChat} className="openChatBtn">Abrir Chat </a>
            {openChat?.id===project.id?(
               <OpenedChat openChat={openChat} currentPage={currentPage} setCurrentPage={setCurrentPage} project={project} setOpenChat={setOpenChat}/>
            ): null}
        </>
    )
}

export default Chat