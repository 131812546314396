import { useEffect, useState } from "react"
import { getAllTeams, getProjects, updateProject } from "../../../../services/api"
import './styles.css'
import Chat from "../chat"
import EditModal from "./editModal"


const FullInfo = ({client}) => {
    const [currentPage, setCurrentPage] = useState(0)
    const [userProjects, setUserProjects] = useState(null)
    const [openChat, setOpenChat] = useState()
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const modifyProject =async () => {
        setIsEditModalOpen(true)
    }

    const callProjects = async () => {
        setUserProjects(null)
        let is_company = (client.type.toString() === '1')
            const response = await getProjects({id: client.id, is_company})
            if (response.length > 0) {
                setUserProjects(response)
            }else{
                setUserProjects(null)
            }
    }

    useEffect(() => {
        callProjects()
    },[client])

    return (
        <div id="fullinfo">
            {client?.type===0?(
                <div className="clientCard card">
                    <h3 className="infoText border">{client.name}</h3>
                    <span className="infoText border">Pessoa {client.type===0?'Física':'Jurídica'}</span>
                    <a href={`mailto:${client.email}`} className="infoText border">Email: {client.email}</a>
                    <span className="infoText">
                        Equipes:
                    {client?.teams?.map((team, index) => (
                        <span key={team?.name+index}>{team?.name}</span>
                    ))}
                    </span>
                </div>
            ):(
                <div className="clientCard card column">
                    <div className="row">
                        <h3 className="infoText border">{client.name}</h3>
                        <span className="infoText">Pessoa {client.type===0?'Física':'Jurídica'}</span>
                    </div>
                    <div className="">
                        {client?.members?.map((member, index) => (
                            <div key={index} className="row">
                                <span className="infoText"><strong>Membro:</strong> {member.name}</span>
                                <a href={`mailto:${member.email}`} className="infoText"><strong>Email:</strong> {member.email}</a>
                            </div>
                        )
                        )}
                    </div>
                </div>
            )
            }

            <div className="projectsContainer">
                {userProjects?userProjects.map((project, index) => (
                    <div key={index} className="projectCard card">
                        <span onClick={modifyProject} className="material-symbols-outlined editIcon">edit
                            <span className="tooltip">Editar Projeto</span>
                        </span>
                        {isEditModalOpen && <EditModal getProjects={callProjects} setIsEditModalOpen={setIsEditModalOpen} project={project} />}
                        <h3>{project.name}</h3>
                        <span className="infoText">Unidade: {project.unity}</span>
                        <a href={`https://app.asana.com/0/${project.asana_id}`} className="infoText" target='_blank'>{project.asana_id?'Asana':''}</a>
                        <a href={`https://schiefleradvocacia.slack.com/archives/${project.slack_id}`} target='_blank'className="infoText">Slack</a>
                        <br/>
                        <Chat currentPage={currentPage} setCurrentPage={setCurrentPage} openChat={openChat} setOpenChat={setOpenChat} project={project}/>
                    </div>
                )):(
                    <div className="card noProjectCard">
                        <span>Esse cliente ainda não tem nenhum projeto cadastrado. Vá no canal do slack referente ao caso e digite o comando  <i>/projeto-cliente</i></span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default FullInfo