import React, {useContext} from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'

import Login from './pages/login/index.jsx'
import Home from './pages/home/index.jsx'
import Clients from './pages/clients/index.jsx'

import { AuthProvider, AuthContext } from './contexts/auth.jsx'
import Financeiro from './pages/financeiro/index.jsx'
import { Header } from './components/header/index.jsx'
import Equipes from './pages/equipes/index.jsx'
import PostGenerator from './pages/posts/postGenerator.jsx'
import LicitaGPT from './pages/gpt/index.jsx'
import GPTRAG from './pages/rag-gpt/index.jsx'

const AppRoutes = () => {
  
    const Private = ({children}) => {
        const { authenticated, loading } = useContext(AuthContext)
        if(loading) {
            return <div className='loading'>Carregando...</div>
        }
        return (authenticated ? children : <Navigate to='/login'/>) 
    }

    return (
        <Router>
            <AuthProvider>
                {/* <Header/> */}
                <Routes>
                    <Route exact path='*' element={<Login/>}/>
                    <Route exact path='/login' element={<Login/>}/>
                    <Route exact path='/home' element={<Private><Home/></Private>}/>
                    <Route exact path='/clients' element={<Private><Clients/></Private>}/>
                    <Route exact path='/financeiro' element={<Private><Financeiro/></Private>}/>
                    <Route exact path='/equipes' element={<Private><Equipes/></Private>}/>
                    <Route exact path='/posts' element={<Private><PostGenerator/></Private>}/>
                    <Route exact path='/licita-gpt' element={<Private><LicitaGPT/></Private>}/>
                    <Route exact path='/gpt-rag' element={<Private><GPTRAG/></Private>}/>
                </Routes>
            </AuthProvider>
        </Router>
    )
}

export default AppRoutes