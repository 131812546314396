import axios from "axios";
import { doRefreshToken } from "./api";
import { StorageCookies } from "./storage_cookies";
import { getTokens } from "./getTokens";

export const api = axios.create({
    baseURL: process.env.REACT_APP_FINANCEIRO_API_URL
});

api.interceptors.request.use(
    config => {
        if (!config.headers['Authorization']) {
            const auth = getTokens()
            config.headers['Authorization'] = `Bearer ${auth?.access_token}`;
            api.defaults.headers['Authorization'] = `Bearer ${auth?.access_token}`
        }
        return config;
    }, (error) => Promise.reject(error)
)

api.interceptors.response.use(
    response => response,
    async (error) => {
        const prevRequest = error?.config;
        if ((error?.response?.status === 403 || error?.response?.status === 401) && !prevRequest?.sent) {
            prevRequest.sent = true;
            let newTokens
            try {
                newTokens = await doRefreshToken()

            } catch (error) {

            } finally {
                if (newTokens?.access_token && newTokens?.refresh_token) {
                    StorageCookies({ access_token: newTokens.access_token, refresh_token: newTokens.refresh_token })
                    api.defaults.headers['Authorization'] = `Bearer ${newTokens?.access_token}`;
                    prevRequest.headers['Authorization'] = `Bearer ${newTokens?.access_token}`
                } else {
                    const auth = getTokens()
                    api.defaults.headers['Authorization'] = `Bearer ${auth?.access_token}`;
                    prevRequest.headers['Authorization'] = `Bearer ${auth?.access_token}`
                }
            }
            return api(prevRequest)
        }
        return Promise.reject(error)
    }
)

//modelo:
//return api.post('/', {data}, { headers: { } });

export const getPaymentsByMonth = async ({ month, year }) => {
    return (await api.get(`/values/payments/range/${month}/${year}/${month}/${year}?limit=150&skip=0`)).data
}

export const paymentsByDate = async ({ by_month = false, currentPage = 0, by_quarter = false, isReverse, date, orderBy, end_date = null }) => {
    const mult = 200
    return (await api.get(`/values/payments?limit=${mult}&skip=${currentPage * mult}&by_month=${by_month}&by_quarter=${by_quarter}&order_by=${orderBy}&reverse=${isReverse}&date=${date}${end_date ? `&end_date=${end_date}` : ''}`)).data
}

export const getAllUnities = async () => {
    return (await api.get(`/unity/all`)).data
}

export const getUnity = async (unity_id) => {
    return (await api.get(`/unity/id/${unity_id}`)).data.name
}

export const getUnityMetrics = async ({ unity_id, by_month = false, currentPage = 0, by_quarter = false, isReverse, date, orderBy, end_date = null }) => {
    const mult = 200
    const response = (await api.get(`/metrics/unity/${unity_id}?limit=${mult}&skip=${currentPage * mult}&by_month=${by_month}&by_quarter=${by_quarter}&order_by=${orderBy}&reverse=${isReverse}&date=${date}${end_date ? `&end_date=${end_date}` : ''}`)).data
    return response
}

export const getCollaboratorMetrics = async ({ by_month = false, currentPage = 0, by_quarter = false, isReverse, date, orderBy, end_date = null }) => {
    const mult = 200
    const response = (await api.get(`/external/metrics/collaborator?limit=${mult}&skip=${currentPage * mult}&by_month=${by_month}&by_quarter=${by_quarter}&order_by=${orderBy}&reverse=${isReverse}&date=${date}${end_date ? `&end_date=${end_date}` : ''}`)).data
    return response
}

export const getMonthBalanceByUnity = async ({ unity_id, month, year }) => {
    if (unity_id === undefined) {
        return
    }
    return (await api.get(`/metrics/unity/${unity_id}?start_date=${year}-${month}-01T00:00:00.01`)).data
}

export const findQuarterByMonthAndYear = async (month, year) => {
    return (await api.get(`/quarter/find?month=${month}&year=${year}`)).data
}

export const getPaymentsByQuarter = async ({ quarter_id }) => {
    return (await api.get(`/values/payments/quarter/${quarter_id}`)).data
}
export const unityBalanceByQuarter = async ({ unity_id, month, year }) => {
    return (await api.get(`/metrics/unity/${unity_id}?start_date=${year}-${month}-05T00:00:01.00&by_quarter=true`)).data
}
export const getPaymentsByYear = async ({ year }) => {
    return (await api.get(`/values/payments/range/01/${year}/12/${year}`)).data
}
export const getUnityBalanceByYear = async ({ unity_id, year }) => {
    if (unity_id === undefined) {
        return
    }
    return (await api.get(`/metrics/unity/${unity_id}?start_date=${year}-01-01T00%3A00%3A00.000000&end_date=${year}-12-31T23%3A59%3A59.000000`)).data
}
export const getPaymentsByRange = async ({ start_month, start_year, final_month, final_year }) => {
    return (await api.get(`/values/payments/range/${start_month}/${start_year}/${final_month}/${final_year}`)).data
}
export const getUnityBalanceByRange = async ({ unity_id, start_month, start_year, final_month, final_year, start_day, final_day }) => {
    if (unity_id === undefined) {
        return
    }
    return (await api.get(`/metrics/unity/${unity_id}?start_date=${start_year}-${start_month}-${start_day}T00%3A00%3A00.000000&end_date=${final_year}-${final_month}-${final_day}T23%3A59%3A59.000000`)).data
}
export const findQuarter = async () => {
    const response = (await api.get(`/quarter/find`)).data
    return response
}