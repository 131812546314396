import { useState } from "react"
import styles from "./postPage.module.css"
import GeneratedForm from "./generatedTextForm"
import PromptSelector from "./promptSelector"

const PostGenerator = () => {
    const [generatedText, setGeneratedText] = useState()

    return(
        <div className={styles.mainContainer}>
        <h1>Gerador de postagens</h1>
        <div className={styles.bodyContainer}>

            <div className={styles.formContainer}>
                <PromptSelector setGeneratedText={setGeneratedText}/>
            </div> 

            <div className={styles.generatedContainer}>
                {generatedText?.content?.length > 0 && (
                    <GeneratedForm setGeneratedText={setGeneratedText} generatedText={generatedText}/>
                )}
            </div>

        </div>
        </div>
    )
}

export default PostGenerator