import Cookies from "js-cookie"
import { useEffect, useMemo, useRef, useState } from "react"
import NewMessage from "./newMessage"
import { getPrevMessages } from "../../../../../services/chat_api"

const OpenedChat = ({project,openChat, setOpenChat, currentPage, setCurrentPage}) => {
    const ROOM_ID = useMemo(() => {
        return project.id},[]) 
    const [prevMessages, setPrevMessages] = useState([])
	const [newMessages, setNewMessages] = useState([])
	const messageRef = useRef()
	const anchorRef = useRef()
	const sendMessageBtnRef = useRef()

    let token = Cookies.get('access_token')
    if (token) {
        token = JSON.parse(token)
    }
    const wsurl = useMemo(() => `wss://api.schiefler.adv.br/chat/connect/ws/room/${ROOM_ID}?token=${token}`, [])

    const ws = useMemo(() => {
        console.log('new conection')
        return new WebSocket(wsurl)
    }, [])

    ws.onmessage = function (event) {
        const received = JSON.parse(event.data).message
        setPrevMessages((prev) => [...prev, received])
		setNewMessages(received)
    }

    const sendMessage = () => {
        if (messageRef.current.value === '') {
            return
        }
        const message = { message: { text: messageRef.current.value } }
        ws.send(JSON.stringify(message))
        const text = message.message
        text.sent_by = 'user@user'
        messageRef.current.value = ''
        setTimeout(() => {
            messageRef?.current.focus()
        }, 300);
    }

    useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			if (entries.some((entry) => entry.isIntersecting)) {
				console.log('showing..')
				setCurrentPage((prev) => prev + 1)
			}
		})
		setTimeout(() => {
			observer.observe(document.querySelector('#ward'))
		}, 3000)
		return () => observer.disconnect()
	}, [])

    const loadMessages = async({reset}) => {
        if (reset) {
			const response = await getPrevMessages({ room_id: ROOM_ID, currentPage: 0 })
			setNewMessages([])
			setPrevMessages(response.messages.reverse())
			setTimeout(() => {
				anchorRef?.current.focus()
				messageRef?.current.focus()
			}, 500)
			return
		}
        const response = await getPrevMessages({room_id: ROOM_ID, currentPage})
        if (response.messages.length === 0) {
			return
		} else if (currentPage === 0) {
			setNewMessages([])
			setPrevMessages(response.messages.reverse())
			setTimeout(() => {
				anchorRef?.current.focus()
				messageRef?.current.focus()
			}, 500)
		} else {
			setPrevMessages((prev) => [...response.messages.reverse(), ...prev])
		}
    }
    useEffect(() => {
		if (currentPage === 0) {
			return
		}
		loadMessages({ reset: false })
	}, [currentPage])

    useEffect(() => {
		setPrevMessages([])
		setCurrentPage(0)
		loadMessages({ reset: true })
	}, [openChat])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            sendMessageBtnRef?.current.click()
        }
    }

    useEffect(() => {
        setTimeout(() => {
            anchorRef?.current.focus()
        }, 500);
        }, [newMessages])

    useEffect(() => {
        loadMessages()
    },[])

    return(
        <div id="chat">
            <div className="chatHeader">
                <div onClick={() => {setOpenChat(null)}} className="material-symbols-outlined icon">Close</div>
                <h2 className="title">Chat - {project.name}</h2>
            </div>
            <div className="chatBody">
                <div id="ward"></div>
                <div className="marginAutoContainer"></div>
                {(
                    prevMessages?.map((message) => (
                        <NewMessage message={message}/>
                    ))
                )}
            <div tabIndex={'1'} ref={anchorRef}></div>
            </div>
            <div className="chatInput">
                <input onKeyDown={handleKeyDown} tabIndex={2} ref={messageRef} type="text"/>
                <span ref={sendMessageBtnRef} onClick={sendMessage} className="material-symbols-outlined icon">send</span>
            </div>
        </div>
    )
}

export default OpenedChat