import { api } from "./api"

export const generateText = async ({ data, prompt }) => {
    const response = await api.post('/wp-poster/poster/generate', { prompt, data })
    return response.data
}
export const publishText = async ({ title, content, categories }) => {
    const response = await api.post('/wp-poster/poster/publish', { title, content, categories })
    return response
}

export const getCategories = async () => {
    const response = await api.get('/wp-poster/categorias/')
    return response
}