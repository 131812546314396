import axios from 'axios'
import { StorageCookies } from './storage_cookies';
import { getTokens } from './getTokens';
import Cookies from 'js-cookie';

let isRefreshing = false;
let failedQueue = [];

export const BASE_URL = process.env.REACT_APP_API_URL// 'https://api.schiefler.adv.br'//'http://localhost:8080'//

export const login_api = axios.create({
    baseURL: BASE_URL
});
export const api = axios.create({
    baseURL: BASE_URL
});
export const refresh_api = axios.create({
    baseURL: BASE_URL
});

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            console.log('chamou a fila', prom)
            prom.resolve(token)
        }
    })

    failedQueue = [];
}

api.interceptors.request.use(
    async config => {
        let auth = getTokens()
        config.headers.Authorization = `Bearer ${auth?.access_token}`;
        return config;
    }, (error) => Promise.reject(error)
)

api.interceptors.response.use(
    response => response,
    async (error) => {
        const prevRequest = error?.config;
        if ((error?.response?.status === 403 || error?.response?.status === 401) && !prevRequest?.sent) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                })
            } else {
                prevRequest.sent = true;
                try {
                    isRefreshing = true
                    const { access_token } = await doRefreshToken()
                    isRefreshing = false
                    processQueue(null, access_token)
                } catch (error) {
                    processQueue(error, null)
                    console.log("🚀 ~ error:", error)
                    Promise.reject(error)
                }
                return api(prevRequest)
            }
        }
        return Promise.reject(error)
    }
)

export const doRefreshToken = async () => {
    const { refresh_token } = getTokens()
    refresh_api.defaults.headers['Authorization'] = `Bearer ${refresh_token}`
    try {
        const response = (await refresh_api.post('/auth/refresh')).data
        StorageCookies({ refresh_token: response.refresh_token, access_token: response.access_token })
        return response
    } catch (error) {
        Cookies.remove('user')
        Cookies.remove('access_token')
        Cookies.remove('refresh_token')
        window.location.href = '/login'
    }
}

export const createSession = async (token) => {
    var a = (await login_api.post('/auth/google-login', { scopes: ["adv", "financeiro", "admin", "me:read", "asana:read", "asana:create"], token: token })).data
    console.log(a)
    return a
}

export const endSession = async (token) => {
    return (await login_api.post('/auth/logout/member', { token: token })).data
}

export const getUserData = async () => {
    return (await login_api.get('/adv/info'))
}

export const getTeamUsers = async () => {
    return (await api.get('/adv/super/member/all')).data
}
export const updateMember = async ({ user_id, teams_array }) => {
    const response = (await api.put('/adv/super/', { id: user_id, teams: teams_array }))
    return response
}


// requisições admin

// receber informações do slack pelo id
export const getUserChannels = async (userId) => {
    return (await api.get(`/adv/slack/view/${userId}`)).data
}

//criar cliente

export const createCustomer = async (customer) => {
    console.log("🚀 ~ customer:", customer)
    try {
        const response = (await api.post('/adv/user/register', customer)).data
        console.log("🚀 ~ created:", response)
        alert('Cliente cadastrado!')
        return response
    } catch (error) {
        alert('Erro ao cadastrar cliente')
        console.log("🚀 ~ error:", error)
    }
}

//criar empresa
export const createCompany = async (company) => {
    return (await api.post('/adv/company/create', company)).data
}


//receber dados do usuário pelo id
export const getUser = async (userId) => {
    return (await api.get(`/adv/user/view/${userId}`)).data
}

//receber projetos do asana
export const getProjects = async ({ id, is_company }) => {
    return (await api.get(`/adv/customer/projects/all/${id}?is_company=${is_company}`)).data
}

export const getAllCustomers = async () => {
    return (await api.get(`/adv/customer/all?limit=100`))
}
export const getCustomerById = async (token, id) => {
    return (await api.get(`/adv/company/info/${id}`))
}

export const getAllTeams = async () => {
    return (await api.get('/adv/team/all')).data
}

export const updateProject = async ({ updated }) => {
    return (await api.put('/adv/customer/projects/update', updated))
}