import './styles.css'
const TdValues = ({row}) => {
    return(
        <tr id='row'>
            <td>
                {row.title}
            </td>
            <td>
                {row.value}
            </td>
        </tr>
    )
}

export default TdValues