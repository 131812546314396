import { useEffect } from 'react'
import formatMoney from '../../../services/formatMoney'
import './styles.css'

export default function UrvTable ({data}){
    const {virtual, real, distributed, urv_price, urvs, name, collaborators, virtual_total_distributed} = data || ''
    const urv_virtual_price = virtual_total_distributed / urvs
    useEffect(() => {
    }, [])
    return (
        <table id='urvtable'>
            <thead>
                <tr>
                    <th className='title'>{name} - Remuneração variável</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='label'>Faturamento Virtual: </td>
                    <td>{formatMoney(virtual)}</td>
                </tr>
                <tr>
                    <td className='label'>Faturamento real:</td>
                    <td>{formatMoney(real)}</td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td className='label'>Total RV(virtual):</td>
                    <td>{formatMoney(virtual_total_distributed)}</td>
                </tr>
                <tr>
                    <td className='label'>Total RV(real):</td>
                    <td>{formatMoney(distributed)}</td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td className='label'>Total de URV (pool):</td>
                    <td>{urvs.toString().replace('.',',')}</td>
                    </tr>
                <tr>
                    <td className='label'>Valor por URV (real) </td>
                    <td>{formatMoney(urv_price)}</td>
                </tr>
            </tbody>
            <tbody className='colabTBody'>
                    <tr className='headTitles'>
                        <td className='label nome'>Nome</td>
                        <td className='label'>URVS</td>
                        <td className='label'>Total Virtual</td>
                        <td className='label'>Total Real</td>
                        <td className='label'>Total Pago</td>
                        <td className='label'>Total à receber</td>
                    </tr>
                {collaborators?.map((colab, index) => (
                    <tr key={index}>
                        <td className='label nome'>{colab.name}</td>
                        <td>{colab.urvs}</td>
                        <td>{formatMoney(urv_virtual_price * colab.urvs)}</td>
                        <td>{formatMoney(colab.pending_var_rem + colab.paid_var_rem)}</td>
                        <td>{formatMoney(colab.paid_var_rem)}</td>
                        <td>{formatMoney(colab.pending_var_rem)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}