import { useEffect, useMemo, useState } from 'react'
import UserCheckbox from './UserCheckbox'
import './styles.css'
import { updateMember } from '../../../services/api'
const User = ({section_team, getUsers, person, teams, access_level}) => {
    const personTeams = []
    person.teams.forEach((team) => personTeams.push(team.slug))

    const [newTeamsArray, setNewTeamsArray] = useState(personTeams)

    const updateMemberTeams = async (e) => {
        e.target.disabled = true
        e.preventDefault()
        const response = await updateMember({user_id: person.id, teams_array: newTeamsArray})
        if(response.status  === 200) {
            alert('Informações salvas!')
            e.target.innerHTML = 'Salvo!'
            getUsers()
        }else{
            alert(`Erro ${response.status} ao salvar informações.`)
            e.target.disabled = false
        }
        setTimeout(() => {
            e.target.disabled = false
            e.target.innerHTML = 'Salvar'
        }, 2000);
    }
    return (
        <div className="userComponent">
            <div className='field'>
                <label>Nome: </label>
                <span>{person.name}</span>
            </div>

            <div className='field'>
                <label>Email: </label>
                <span>{person.email}</span>
            </div>

            <form className='teamsContainer'>
                {teams.map((team, i) => (
                   <UserCheckbox section_team={section_team} key={i} newTeamsArray={newTeamsArray} setNewTeamsArray={setNewTeamsArray} access_level={access_level} person={person} team={team} />
                ))}
            {access_level>=1?<button onClick={updateMemberTeams}>Salvar</button>:null}
            </form>
        </div>
    )
}

export default User