import React, {useEffect } from 'react'
import {Header} from '../../components/header'
import logo from '../../images/logo-icon.png'
import { gapi } from 'gapi-script'

import "./styles.css"
import {LoginBtn, clientId} from '../../components/GoogleBtns'

const Login = () => {
   
    useEffect(()=>{
        function start () {
            gapi.client.init({
                clientId: clientId,
                scope: ""
            })
        }
        gapi.load('client:auth2', start)
    })

    return (
        <div id="login">
            <div className="form">
                <h1 className='formTitle'>Área do Advogado</h1>
                <img className='login-logo' alt='schiefler logo' src={logo}/>
                <LoginBtn/>
            </div>
        </div>
    )

}

export default Login