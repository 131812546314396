import './styles.css'

const NewMessage = ({ message }) => {
	if (!message.sent_at){
		message.sent_at = (new Date()).toISOString()
	}
	const sent_by_check = message.sent_by.split('@')[1]

        return (
			<div className={`messageContainer ${sent_by_check === 'schiefler.adv.br'?'schiefMessageContainer':'clientMessageContainer'}`}>
				<span className={`message ${sent_by_check === 'schiefler.adv.br'?'schiefMessage':'clientMessage'}`}>
					{message.text}
					{message.sent_at?<span className='time'>{message?.sent_at.slice(11,16)}</span>:''}
				</span>
			</div>
		)
}
export default NewMessage
