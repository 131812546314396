import './styles.css'


const LoadContainer = () => {
    return (

    <div className="loading-div lds-ripple">
        <div></div>
        <div></div>
    </div>
    )
}
export default LoadContainer