import './styles.css'

const ArrowBackToHome = () => {

    const handleReturnBtn = () => {
        window.location.href = '/home'
    }

    return(
        <span onClick={handleReturnBtn} className="material-symbols-outlined arrowBack">
            keyboard_backspace
        </span>
    )
}

export default ArrowBackToHome