import { useContext, useEffect, useState } from 'react'
import { getAllTeams, getTeamUsers } from '../../services/api'
import './styles.css'
import User from './user'
import { AuthContext } from '../../contexts/auth'
import ArrowBackToHome from '../../components/arrow-back'
import _, { indexOf } from 'lodash'
import Team from './team'

const Equipes = () => {
    const { user } = useContext(AuthContext)
    const [teams, setTeams] = useState ()
    const [data, setData] = useState()

    const getUsers = async () => {
        let ordered = []
        
        const teams = await getAllTeams()
        const users = await getTeamUsers ()

        await Promise.all([
            teams.forEach((team) => {
                if(team.slug==='escritorio'){
                    return
                }

                const filtered = []

                for (let i = 0; i<users.length; i++){
                    for( let j = 0; j<users[i].teams.length; j++){
                        if(users[i].teams[j].slug === team.slug){
                            if(!filtered.includes(users[i])){
                                filtered.push(users[i])
                            }
                        }
                }
            }

                const newTeam = {
                    team: team,
                    members: filtered
                }
                ordered.push(newTeam)
        })
    ])
        setTeams(teams)
        setData(ordered)
    }

    useEffect(() => {
        getUsers()
    }, [])
    return(
        <div id="equipes">
            <h1 className='title'>Equipes</h1>
            <ArrowBackToHome/>
            <div className='users-container'>
                {data?.map((team, index) => (
                    <Team getUsers={getUsers} key={index} all_teams={teams} access_level={user.access_level} team={team} />
))}
            </div>
        </div>
    )
}

export default Equipes