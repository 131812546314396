import { useState } from "react"

const UserCheckbox = ({section_team, team, person, access_level, newTeamsArray, setNewTeamsArray}) => {
    const disableForm = !(access_level >= 1)
    const [checked, setChecked] = useState(newTeamsArray.includes(team.slug))
    if (team.slug==='escritorio'){return}

    const toggleCheckbox = (e) => {
        if (e.target.checked){
            const newArray = [...newTeamsArray, e.target.value]
            setChecked(true)
            setNewTeamsArray(newArray)
        }else{
            const newArray = newTeamsArray
            newArray.splice(newArray.indexOf(e.target.value), 1)
            setChecked(false)
            setNewTeamsArray(newArray)
        }
    }
    return(
        <div className='teamsField'>
            <input checked={checked} onChange={e => toggleCheckbox(e)} disabled={disableForm} id={`${team.slug}${person.id}${section_team}`} type="checkbox" name={`${team.slug}${person.id}${team.name}`} value={team.slug}/>
            <label htmlFor={`${team.slug}${person.id}${section_team}`}>{team.name}</label>
        </div>
    )
}

export default UserCheckbox