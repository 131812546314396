import { useEffect, useState } from 'react'
import styles from './categories.module.css'
import Category from './category'

const Categories = ({selectedCategories, categories}) => {
    useEffect(() => {console.log(selectedCategories)} , [selectedCategories])

    const [isOpen, setIsOpen] = useState(true)
    return(
        <>
            <button className={styles.button} onClick={() => setIsOpen(prev => !prev)} type='button'>Editar Categorias: </button>
            <div className={styles.container}>  
                { isOpen && categories.map((category) => (
                    <Category selectedCategories={selectedCategories} category={category}/>
                ))}
            </div>
        </>
    )
}

export default Categories