import './styles.css'
import { Button } from './button'
import { useContext } from 'react'
import { AuthContext } from '../../contexts/auth'

import { LogoutBtn } from '../../components/GoogleBtns'

const buttons = [
    {title: 'Novo Post', icon: 'group', link: '/posts', target:'_self'},
    {title: 'Clientes', icon: 'group', link: '/home', target:'_self'},
    {title: 'LicitaGPT', icon: 'bolt', link: '/gpt-rag', target:'_self'},
    {title: 'CRM', icon: 'list', link: 'https://crm.schiefler.adv.br/', target:'_blank'},
    {title: 'Financeiro', icon: 'attach_money', link: '/financeiro', target:'_self'},
    // {title: 'CRM', icon: 'list', link: 'https://docs.google.com/spreadsheets/d/14gjRzidkO3civlpbnzqygS2tgpvl-ptsKMpmn3nXscA/edit#gid=347603247', target:'_blank'},
    {title: 'Plano de Comunicação', icon: 'checklist', link: 'https://docs.google.com/spreadsheets/d/1mt8dc-n9lFvIc2r3-wHyCYtkWkpNBP_E_Bi3RCHvEA8/edit#gid=338502751', target:'_blank'},
    {title: 'Gerador de Anexos', icon: 'file_present', link: 'https://nit.schiefler.adv.br/schief/anexos/', target:'_blank'},
    {title: 'Gerador de Contratos', icon: 'add_notes', link: 'https://docs.google.com/forms/d/e/1FAIpQLSfKql5oY8SPWvX1CCzK_w7Z0ezgKUql890WwdfJPaF6M41XWg/viewform', target:'_blank'},
    {title: 'Gerador de Propostas', icon: 'overview', link: 'https://docs.google.com/forms/d/e/1FAIpQLSfY-UzxR9o8ACbXYKBl_-a6TVX0AvCRETChvkAyI3UCRPnp-A/viewform', target:'_blank'},
    // {title: 'Adicionar Novo PC', icon: 'group_add', link: 'https://docs.google.com/forms/d/e/1FAIpQLSdGZGR8ZSwiKToWTsyu05YSa3mFoXr0QuOejVPNaGsixcXTOg/viewform', target:'_blank'},
    // {title: 'Adicionar Novo Parceiro', icon: 'handshake', link: 'https://docs.google.com/forms/d/e/1FAIpQLSfPfogp8kuCfO5GXs3TARyyjKzmkvJNNcsjkFdZk4DAh9MIqQ/viewform', target:'_blank'},
    {title: 'Slack', icon: 'hub', link: 'slack://open', target:'_blank'},
    // {title: 'Progresso das Frentes', icon: 'rocket_launch', link: 'https://rocket-front-end-two.vercel.app/', target:'_blank'},
    {title: 'Nova Chamada de Vídeo', icon: 'duo', link: 'https://meet.new', target:'_blank'},
    {title: 'Novo Docs', icon: 'description', link: 'https://docs.new/', target:'_blank'},
    {title: 'Equipes', icon: 'group', link: '/equipes', target:'_self'},
]

const Home = ({navigation}) => {
    const { user } = useContext(AuthContext)

        let now = new Date()
        now = now.getHours()
        let wellcome = ''

        if (now > 5 && now <= 12) {
            wellcome = 'Bom dia'
        }
        if (now > 12 && now <= 18) {
            wellcome = 'Boa tarde'
        }
        if (now > 18 || now <= 5) {
            wellcome = 'Boa noite'
        }
    return (
        <div id="home">
            <h1 className="title">Área do Advogado</h1>
            <h2 className='welcome'>{wellcome},<br/> {user?.name}</h2>
            <div className='btnsContainer'>
               {buttons.map((btn,index)=><Button key={index} title={btn.title} target={btn.target} link={btn.link || '/'} icon={btn.icon} />)}
            </div>
            <LogoutBtn/>
        </div>
    )
}

export default Home