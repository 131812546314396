import User from "../user"
import './styles.css'
const Team = ({team, all_teams, access_level, getUsers}) => {
    return(<div className="teamContainer">
        <h1 className="teamTitle">{team.team.name}</h1>
        <div className="membersContainer">
            {team?.members?.map((member, index)=> (
                <User section_team={team.team.name} getUsers={getUsers} access_level={access_level} key={index} teams={all_teams} person={member}/>
            ))}
        </div>
    </div>
)
}

export default Team