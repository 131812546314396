import './App.css';
import AppRoutes from './AppRoutes';
import { Header } from './components/header';

function App() {
  return (

    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
