import { findThisQuarter, month, year } from '../../../services/today'
import './styles.css'
import { createContext, useEffect, useRef, useState } from "react"
import { StoragePeriod } from "./handleCookies"
import { slugToTx, findNumberByQuarter } from "../utils"
import { getUnityMetrics } from '../../../services/api_financeiro'

export const DatePickerContext = createContext()

export const DatePickerProvider = ({children}) => {
    
    const [period, setPeriod] = useState()
    const [unity, setUnity] = useState()
    const chosenDay_ref = useRef()
    const chosenMonth_ref = useRef()
    const chosenQuarter_ref = useRef()
    const chosenYear_ref = useRef()
    const chosenInterval_inicial_ref = useRef()
    const chosenInterval_final_ref = useRef()
    const button_ref = useRef()

    const handleSubmit = (fun) => {
        const {date, end_date} = formatCurrentDate()
        const data = {
            period,
            date,
            end_date,
            unity
        }
        StoragePeriod({data})
        fun();
    }
    const formatCurrentDate = () => {
        let title
        let date
        let end_date = ''
        let str = ''
        let month = ''
        let year = ''
        try {
            switch(period){
                case 'by_day':
                    str = chosenDay_ref.current.value
                    if(str){
                        str = str.replaceAll('-', '/')
                    }
                    title=str.split('/').reverse().join('/')
                    date = (new Date(str)).toISOString()
                    break
                case 'by_month':
                    str = chosenMonth_ref.current.value
                    if(str){
                        str = str.replaceAll('-', '/')
                    }
                    title=str.split('/').reverse().join('/')
                    date = (new Date (`${str}/01`).toISOString())
                    break
                case 'by_quarter':
                    month = chosenQuarter_ref?.current?.value
                    year = chosenYear_ref?.current?.value
                    date = (new Date(`${year}/${month}/01`)).toISOString()
                    title = `${slugToTx(month)}/${year}`
                    break
                case 'by_year':
                    year = chosenYear_ref.current.value
                    date = (new Date(`${year}/01/01`)).toISOString()   
                    end_date = (new Date(`${year}/12/31`)).toISOString()
                    title=year
                    break
                case 'by_range':
                    const old_date =chosenInterval_inicial_ref.current.value.replaceAll('-', '/')
                    const old_end_date = chosenInterval_final_ref.current.value.replaceAll('-', '/')
                    date = (new Date(old_date)).toISOString()
                    end_date = (new Date(old_end_date)).toISOString()
                    title=`${old_date.split('/').reverse().join('/')} - ${old_end_date.split('/').reverse().join('/')}` 
                    break
                default:
                    console.log('switch/case error -> ', period)     
            }
        } catch (error) {
            console.log('erro ao processar data. -> ', date, end_date)
        }
        return {date, end_date, title}
    }

    return(
        <DatePickerContext.Provider 
        value={{formatCurrentDate, unity, setUnity, period, setPeriod, chosenDay_ref, chosenMonth_ref, chosenQuarter_ref,
         chosenYear_ref, chosenInterval_inicial_ref, chosenInterval_final_ref, handleSubmit, button_ref}}>
            
            {children}
        </DatePickerContext.Provider>
    )
}

export default function DatePicker ({period, getMetricsByMonth, getMetricsByQuarter, getMetricsByYear, getMetricsByRange}) {
    const chosenMonth_ref = useRef()
    const chosenQuarter_ref = useRef()
    const chosenYear_ref = useRef()
    const chosenInterval_inicial_ref = useRef()
    const chosenInterval_final_ref = useRef()
    const quarterButtonRef = useRef()

    const [thisQuarter, setThisQuarter] = useState()

    // async function findthisquarter () {
    //     const response = await findThisQuarter()
    //     const quarterSlug = response.name
    //     const quarter_name = quarterSlug.split('/')[0].toLowerCase()
    //     setThisQuarter(quarter_name)
    // }
    // useEffect(()=>{
    //     findthisquarter()
    // },[])
    useEffect(()=>{
        if(quarterButtonRef.current){
            quarterButtonRef.current.click()
        }
    },[thisQuarter])


    return(
        <div id='dataPicker'>
            <label className='title'>Escolha o período: </label>
            {period==='1'?(
                <>
                    <div className='container'>
                        <div className='field'>
                            <label>Mês:</label>
                            <input ref={chosenMonth_ref} type='month' defaultValue={`${year}-${month}`}/>   
                        </div>
                    </div>
                    <button onClick={() =>getUnityMetrics({
                        unity_id: 1,
                        by_month: true,
                        date: new Date(chosenMonth_ref.current.value+"-1").toISOString()
                    })}>Filtrar</button>
                </>
            ):period==='3'?(
                <>
                    <div className='container'>
                        <div className="field">
                            <label>Trimestre: </label>
                            <select ref={chosenQuarter_ref} defaultValue={'Jan/2024'}>
                                <option value='jan'>T1</option>
                                <option value='apr'>T2</option>
                                <option value='jul'>T3</option>
                                <option value='oct'>T4</option>
                            </select>
                        </div>
                        <div className="field">
                            <label>Ano: </label>
                            <input ref={chosenYear_ref} type="number" min="2024" max="2099" step="1" defaultValue={year} />
                        </div>
                    </div>
                    <button ref={quarterButtonRef} onClick={() =>getMetricsByQuarter({
                        unity_id: 1,
                        by_quarter: true,
                        date: (new Date(`${chosenYear_ref.current.value}-${findNumberByQuarter(chosenQuarter_ref.current.value)}-01`)).toISOString()
                    })}>Filtrar</button>
                </>
            ):period==='12'?(
                <>
                    <div className='container'>
                        <div className="field">
                            <label>Ano: </label>
                            <input ref={chosenYear_ref} type="number" min="1900" max="2099" step="1" defaultValue={year} />
                        </div>
                    </div>
                    <button onClick={() =>getUnityMetrics({
                        unity_id: 1,
                        by_month: false,
                        date: (new Date(`${chosenYear_ref.current.value}/01/01`)).toISOString(),
                        end_date: (new Date(`${chosenYear_ref.current.value}/12/31`)).toISOString()
                    })}>Filtrar</button>
                </>
            ):(
                <>
                    <div className='container'>
                        <div className="field">
                            <label>Data inicial:</label>
                            <input ref={chosenInterval_inicial_ref} type='date'/>   
                        </div>
                        <div className="field">
                            <label>Data final:</label>
                            <input ref={chosenInterval_final_ref} type='date'/>   
                        </div>
                    </div>
                    <button onClick={() => getMetricsByRange({initial: chosenInterval_inicial_ref.current.value, final: chosenInterval_final_ref.current.value})}>Filtrar</button>
                </>
            )}
        </div>
    )
}