import formatMoney from "../../../services/formatMoney"
import ChartTotal from "../charts/total"
import TdValues from "./tdValues"

import './styles.css'
import UrvTable from "../urvTable"
import PaymentsTable from "../paymentsTable"

const MetricsTable = ({period, values, isUnity=false, showUda, showUdf, showEscritorio}) => {
    const {virtual, real, pendency, expenses, result, title, payments} = values
    if(!showUda && values.name==='UDA'){
        return ('')
    }else if (!showUdf && values.name==='UDF'){
        return ('')
    }else if (!showEscritorio && values.name==='Escritório'){
        return ('')
    }else
    {
    return(
            <div className={values.name==='UDA'?'containerByUnity uda_order':
            values.name==='UDF'?'containerByUnity udf_order':
            values.name==='Escritório'?'containerByUnity escritorio_order':
            'containerByUnity'}>
                <h1 className="titleByUnity">{title}</h1>
                {isUnity?(
                    <UrvTable data={values}/>
                ):''}
                <div className="tableAndChartContainer">
                    <table id='metricsTable'>
                        <tbody>
                            <TdValues row={{title: 'Receita total', value: `${formatMoney(virtual)}`}}/>
                            <TdValues row={{title: 'Pagamentos recebidos', value: `${formatMoney(real)}`}}/>
                            <TdValues row={{title: 'Pendente', value: `${formatMoney(pendency)}`}}/>
                            <TdValues row={{title: 'Despesas', value: `${formatMoney(expenses)}`}}/>
                            {/* <TdValues row={{title: 'Pagamentos Fixos', value: `${formatMoney(payments)}`}}/> */}
                            <TdValues row={{title: 'Resultado', value: `${formatMoney(result)}`}}/>
                        </tbody>
                    </table>
                    <ChartTotal data={{title:title,result:result,virtual:virtual,real:real,pendency:pendency,expenses:expenses,payments:payments}}/>
                </div>
                {isUnity?(
                    // <PaymentsTable unity_payments={unity_payments}/>
                    <></>
                ):''}
            </div>	
    
    )
}
}

export default MetricsTable