import React, { createContext, useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { createSession, getUserData, endSession, login_api } from '../services/api.js'
import { StorageCookies } from '../services/storage_cookies.js'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null)
	const [token, setToken] = useState()
	const [loading, setLoading] = useState(true)
	const domain = process.env.REACT_APP_DOMAIN

	const checkCookies = async () => {
		const cUser = Cookies.get('user', { domain: domain })
		const rToken = Cookies.get('refresh_token', { domain: domain })
		if (cUser && rToken){
			setUser(JSON.parse(cUser))
		}else{
		}	
		setLoading(false)
	}
	useEffect(() => {
		checkCookies()
	}, [])

	const login = async (googleToken) => {
		try {
			const response = await createSession(googleToken)
                const {refresh_token,access_token} = response
				login_api.defaults.headers.Authorization = `Bearer ${access_token}`
				const userResponse = await getUserData()
				const userData = userResponse.data				
				StorageCookies({refresh_token: refresh_token, user: userData, access_token: access_token})
				setUser(userData)
				window.location.href= '/home'
		} catch (err) {
			console.log(err)
		}
	}

	const logout = () => {
		Cookies.remove('user', { domain: domain })
		Cookies.remove('access_token', { domain: domain })
		Cookies.remove('refresh_token', { domain: domain })
		setUser(null)
		endSession(token)
		window.location.href= '/login'
	}

	return (
		<AuthContext.Provider value={{ authenticated: !!user, user, loading, login, logout }}>
			{children}
		</AuthContext.Provider>
	)
}
