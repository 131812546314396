import { useRef, useState } from "react"
import styles from "./form.module.css"
import { generateText } from "../../../services/wp_poster"
import LoadContainer from "../../../components/loading"

const PostForm = ({selectedPrompt, setGeneratedText}) => {
    const [isLoading, setIsLoading] = useState(false)
    const contentRef = useRef()

    const handleSubmit = async(e) => {
        setGeneratedText()
        e.preventDefault()
        if (contentRef.current.value === '' || contentRef.current.value.length <= 10) {
            alert('Por favor, preencha o campo adequadamente.')
            return
        }
        setIsLoading(true)
        generateText({data: contentRef.current.value, prompt: selectedPrompt.prompt}).then((response) => {
            console.log("🚀 ~ response:", response)
            setGeneratedText({title: response.title, content: response.content})
        }).catch((error) => {
            console.log("🚀 ~ error:", error)
            alert(`Algo deu errado. Por favor, tente novamente.`)
            Promise.reject(error)
        }).finally(()=>{
            setIsLoading(false)
        })
    }
    return(
        <form className={styles.form} onSubmit={handleSubmit}>
            <label className={styles.label}>Cole a ementa + referência: </label>
            <br/>
            <textarea ref={contentRef}/>

            <input disabled={isLoading} type="submit"/>
            {isLoading && <LoadContainer/>}
        </form>
    )
}

export default PostForm