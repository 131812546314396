import { useRef } from 'react'
import styles from './editModal.module.css'
import { updateProject } from '../../../../services/api'


const area = ['',"da1","da2","da3","da4","da5","udf","px"]
const EditModal = ({getProjects, project, setIsEditModalOpen}) => {
    console.log("🚀 ~ project:", project)
    
    const nameRef = useRef()
    const slackRef = useRef()
    const asanaRef = useRef()
    const clockifyRef = useRef()
    const areaRef = useRef()

    
    const modifyProject = async () => {
        const asana_formated = asanaRef.current.value.split('com/0/')[1] || asanaRef.current.value
        const slack_formated = slackRef.current.value.split('/archives/')[1] || slackRef.current.value
        const updated = {
            id: project.id,
            area: project.area,
            name: project.name,
            // area: areaRef.current.value,
            // name: nameRef.current.value,
            asana_id: asana_formated,
            slack_id: slack_formated,
            clockify_id: clockifyRef.current.value || ''
        }
        console.log("🚀 ~ updated:", updated)
    const response = await updateProject({updated})
    console.log("🚀 ~ response:", response)
    if(response.status===200){
        await getProjects()
        alert('Projeto atualizado!')
        setIsEditModalOpen(false)
    }else{
        alert(`Erro ${response.status}`)
    }
    }
    return(
        <div className={styles.container}>
            <div onClick={() => setIsEditModalOpen(false)} className={styles.closemodal}></div>
            <div className={styles.centerContainer}>
                <h4>Editar Projeto</h4>
                <div className={styles.row}>
                    <div className={styles.field}>
                        <label>Nome: </label>
                        <input disabled defaultValue={project.name} ref={nameRef} type="text"/>
                    </div>
                    <div className={styles.field}>
                        <label>Área: </label>
                        <select disabled defaultValue={project.area} ref={areaRef}>
                            {area?.map((item) => (
                                <option value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className={styles.field}>
                    <label>Asana: </label>
                    <input defaultValue={`https://app.asana.com/0/${project.asana_id}`} ref={asanaRef} type="text"/>
                </div>
                <div className={styles.field}>
                    <label>Canal do Slack: </label>
                    <input defaultValue={`https://schiefleradvocacia.slack.com/archives/${project.slack_id}`} ref={slackRef} type="text"/>
                </div>
                <div className={styles.field}>
                    <label>Clockify: </label>
                    <input defaultValue={project.clockify_id} ref={clockifyRef} type="text"/>
                </div>
                
            <button type='button' onClick={modifyProject}>Enviar</button>
            </div>
        </div>
    )
}

export default EditModal