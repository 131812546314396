import styles from './categories.module.css'
import { useState } from "react"

const Category = ({selectedCategories, category}) => {
    const [checked, setChecked] = useState(selectedCategories.includes(category.id))

    
    const editCategory = () => {
        const includes = selectedCategories.includes(category.id)
        const index = selectedCategories.indexOf(category.id)
        if (includes){
            selectedCategories.splice(index, 1)
            setChecked(false)
        }else{
            selectedCategories.push(category.id)
            setChecked(true)
        }
        console.log(selectedCategories)
    }

    return (
            <div className={styles.field}>
                <input checked={checked} onChange={() => editCategory()} 
                        type='checkbox' id={category.slug} 
                        name={category.slug} 
                        value={category.id} />
                <label htmlFor={category.slug}>{category.name}</label>
            </div>
    )
}

export default Category