import { useEffect, useMemo, useState } from 'react'
import styles from './generatedForm.module.css'
import TextEditor from '../textEditor'
import { EditorState, ContentState, convertFromHTML} from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import { getCategories, publishText } from '../../../services/wp_poster';
import Categories from '../categories';
import LoadContainer from '../../../components/loading';


const GeneratedForm = ({setGeneratedText,generatedText}) => {
    let selectedCategories = useMemo(() => [], []) 
    const [categories, setCategories] = useState()
    const [editableTitle, setEditableTitle] = useState (generatedText.title)
    const [isPosting, setIsposting] = useState(false)
    const [editable, setEditable] = useState( () =>
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(generatedText.content)))
    )

    useEffect(() => {
        getCategories().then((response) => {
            console.log(response.data)
            setCategories(response.data)
        })
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const content = stateToHTML(editable.getCurrentContent())
        setIsposting(true)
        publishText({title: editableTitle, content: content, categories: selectedCategories}).then((response => {
            alert('Texto cadastrado!')
            window.open(response.data.url, '_blank')
            setGeneratedText(null)
            console.log(response)
            
        })).catch((error) => {
            alert(`Ocorreu um erro ao tentar publicar o texto.`)
            Promise.reject(error)
        }).finally(() => {
            setIsposting(false)
        })
    }
    return(
        <form className={styles.form} onSubmit={handleSubmit}>
            {isPosting && <LoadContainer/>}
            <h2 className={styles.title}>Texto Gerado: </h2>
            { categories?.length > 0 && <Categories selectedCategories={selectedCategories} categories={categories}/> }
            <h3>Título: </h3>
            <textarea className={styles.postTitleInput} type='text' value={editableTitle} onChange={(e) => setEditableTitle(e.target.value)}/>
            <div className={styles.textEditorContainer}>
                <TextEditor value={editable} setValue={setEditable}/>
            </div>
            <input disabled={isPosting} className={styles.button} type="submit" value='Cadastrar texto no wordpress' />
        </form>
    )
}

export default GeneratedForm