
export const Button = ({title, icon, link, target}) => {
    let activateEmBreve = false
    if (title === "Clientes" || title === "Gerador de Anexos") {
        activateEmBreve = true
    }
    return (
        <a href={link} className='link buttonHover' target={target}>
            <span className={activateEmBreve?'emBreveOn emBreve':'emBreve'}>Em breve</span>
            <div className='homeBtns'>
                <span className='material-symbols-outlined btnIcon'>{icon}</span>
                <span className='btnTitle'>{title}</span>
            </div>
        </a>
    )
}