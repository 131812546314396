import BarChart from '../../../../components/charts/barChart'
import { useEffect, useState } from 'react'
import './styles.css'
const ChartTotal = ({data}) => {
    // const {result, real, virtual, title, pendency, expenses} = data
	const [barValues, setBarValues] = useState(null)
	const [chartName, setChartName] = useState()
	function get_new_values() {
		const newValues = {
			labels: ['Total'],
			datasets: [
				{
					label: 'Resultado',
					data: [data.result],
					backgroundColor: ['rgba(0, 255,0, 0.5)'],
					borderColor: ['rgb(0, 255, 55)'],
					borderWidth: 1,
				},
				{
					label: 'Receita',
					data: [data.virtual],
					backgroundColor: ['rgba(0, 0, 255, 0.5)'],
					borderColor: ['rgb(0, 0, 255)'],
					borderWidth: 1,
				},
				{
					label: 'Pagamentos recebidos',
					data: [data.real],
					backgroundColor: ['rgba(0, 220, 0, 0.2)'],
					borderColor: ['rgb(0, 220, 0)'],
					borderWidth: 1,
				},
				{
					label: 'Pendencias',
					data: [data.pendency],
					backgroundColor: ['rgba(220, 0, 220, 0.5)'],
					borderColor: ['rgb(220, 0, 220)'],
					borderWidth: 1,
				},
				{
					label: 'Despesas',
					data: [data.expenses],
					backgroundColor: ['rgba(220, 0, 0, 0.5)'],
					borderColor: ['rgb(220, 0, 0)'],
					borderWidth: 1,
				},
				// {
				// 	label: 'Pagamentos Fixos',
				// 	data: [data.payments],
				// 	backgroundColor: ['rgba(220, 161, 0, 0.5)'],
				// 	borderColor: ['rgb(220, 161, 0)'],
				// 	borderWidth: 1,
				// },
			],
		}
		return newValues
	}

	async function doAudit () {
		setChartName(`${data.title}`)
		var newValues = get_new_values()
		setBarValues(newValues)
	}

	useEffect(() => {
		doAudit()
	},[])

	useEffect(() => {
	}, [data])

	return (
    <div className='metricsChartContainer'>
            {barValues?<BarChart title={chartName} data={get_new_values()}/>:''}
    </div>
	)
}

export default ChartTotal
