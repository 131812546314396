import Cookies from "js-cookie"

export const getTokens = () => {
    const domain = process.env.REACT_APP_DOMAIN
    const c_token = Cookies.get('access_token', { domain: domain })
    const r_token = Cookies.get('refresh_token', { domain: domain })

    let access_token
    let refresh_token

    if (c_token) {
        access_token = JSON.parse(c_token)
    }
    if (r_token) {
        refresh_token = JSON.parse(r_token)
    }
    if (!r_token) {
        const button = document.querySelector('.logoutButton')
        if (button) {
            console.log(button)
            button.click()
            return
        }
    }

    if (!c_token) {
        access_token = ''
    }

    return { access_token, refresh_token }
}