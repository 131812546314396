import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'

import '../styles.css'

const BarChart = ({title, data}) => {

	return (
		<div className='biggerChart'>
			{data?<>
				<h2 className='title'>{title}</h2>
				<Bar data={data}/>
			</>
			:<></>}
		</div>
	)
}
// const labels = [
// 	'jan',
// 	'fev',
// 	'mar',
// 	'abr',
// 	'mai',
// 	'jun',
// 	'jul',
// 	'ago',
// 	'set',
// 	'out',
// 	'nov',
// 	'dez',
// ]

// const lineValues = {
// 	labels: labels,
// 	datasets: [
// 		{
// 			label: 'Valores Virtuais',
// 			data: ['50000', '70000', '100000', '150000'],
// 			borderColor: 'blue',
// 			backgroundColor: 'blue',
// 			pointHoverBackgroundColor: 'rgba(0,0,255,0.4)',
// 		},
// 		{
// 			label: 'Valores Reais',
// 			data: ['25000', '15000', '70000', '78000'],
// 			borderColor: 'green',
// 			backgroundColor: 'green',
// 		},
// 	],
// }
// const barValues = {
// 	labels: labels,
// 	datasets: [
// 		{
// 			label: 'Valores Virtuais',
// 			data: ['50000', '70000', '100000', '150000'],
// 			backgroundColor: ['rgba(0, 0, 255, 0.2)'],
// 			borderColor: ['rgb(0, 0, 255)'],
// 			borderWidth: 1,
// 		},
// 		{
// 			label: 'Valores Reais',
// 			data: ['25000', '15000', '70000', '78000'],
// 			backgroundColor: ['rgba(0, 220, 0, 0.2)'],
// 			borderColor: ['rgb(0, 220, 0)'],
// 			borderWidth: 1,
// 		},
// 	],
// }

// export const LineChart = (values) => {
// 	return (
// 		<div className='biggerChart'>
// 			<Line data={values} />
// 		</div>
// 	)
// }

// export const BarChart = (values) => {
// 	return (
// 		<div className='biggerChart'>
// 			<Bar data={values} />
// 		</div>
// 	)
// }

export default BarChart
