import { useContext, useEffect, useState } from 'react'
import './styles.css'
// import { quarterAuditByMonth, balanceByYear, findQuarterByMonthAndYear, getAllUnities, getMonthBalanceByUnity, getUnityUrv, getUnityBalanceByRange, getUnityBalanceByYear, unityBalanceByQuarter, getPaymentsByQuarter, getPaymentsByMonth, getPaymentsByYear, getPaymentsByRange } from '../../services/api_financeiro'
import { findQuarterByMonthAndYear, getAllUnities, getMonthBalanceByUnity,
	 getUnityBalanceByRange, getUnityBalanceByYear, unityBalanceByQuarter, 
	 getPaymentsByQuarter, getPaymentsByMonth, getPaymentsByYear,
	  getPaymentsByRange, 
	  getUnity, paymentsByDate, getUnityMetrics, getCollaboratorMetrics} from '../../services/api_financeiro'
	  
import DatePicker from './datepicker'
import MetricsTable from './table'
import { findNumberByQuarter, slugToTx } from '../../services/dateSlug'
import { AuthContext } from '../../contexts/auth'
import { formatCurrentDate } from './utils'
import { getUser,getUserData } from '../../services/api'


const Metricas = () => {
	const { user } = useContext(AuthContext)
	const [showUda,setShowUda] = useState(true)
    const [showUdf,setShowUdf] = useState(true)
    const [showEscritorio, setShowEscritorio] = useState(true)
	const [showTotal, setShowTotal] = useState(false)
	const [totalData, setTotalData] = useState()
	const [period,setPeriod] = useState('3')

	const title = "Teste"
//por unidade
	const [dataByUnityOriginal, setDataByUnityOriginal] = useState([])
	const [dataByUnity, setDataByUnity] = useState([])

//metricas urv 

	const getMetrics = async (data) => {
		setTotalData()
		setDataByUnityOriginal()

		// const {date, end_date} = formatCurrentDate()
		const date = Date.now()
		const end_date = Date.now()

		if(!date){
			return
		}
		let unitiesData = []
		let all_virtual_values = 0
		let all_real_values = 0
		let all_expenses = 0
		let all_pendency = 0
		let all_result = 0
		let all_urvs = 0

		const balance_response = await getCollaboratorMetrics({
			by_month: period==='by_month',
			by_quarter: true,//period==='by_quarter'
			isReverse: false,
			date: data.date,
			end_date: data.end_date
		})
		const {name, expenses, 
				real_values, 
				urv_value, 
				virtual_values, 
				total_urvs, collaborators, 
				virtual_total_distributed, 
				total_distributed, balance, payments} = balance_response
		// const unity_payments = payments.find((item) => item.name===unity)
		const pendency = virtual_values-real_values
		const result = real_values-expenses
		// if (unity !== 'Escritório'){
		// 	all_virtual_values+=virtual_values
		// 	all_real_values+=real_values
		// 	all_result+=result
		// }
		all_urvs+=total_urvs
		all_expenses+=expenses
		const info = {
			name: name,
			urvs: total_urvs,
			expenses: expenses + payments,
			payments: payments, 
			real: real_values, 
			virtual: virtual_values, 
			pendency: pendency<0?0:pendency, 
			result: balance,
			title: name,
			virtual_total_distributed,
			collaborators,
			distributed: total_distributed,
			urv_price: urv_value,
		}
		unitiesData.push(info)

				const total_todo_escritorio = {
					name: 'Total de todas unidades',
					virtual: all_virtual_values,
					real: all_real_values,
					expenses: all_expenses,
					pendency: all_pendency<0?0:all_pendency,
					result: all_result,
					urvs: all_urvs,
					title: `Total - ${title}`
				}
				setTotalData(total_todo_escritorio)
				setDataByUnity(unitiesData)
				setDataByUnityOriginal(unitiesData)
	}

	useEffect(()=> {
		setDataByUnity()
	},[period])

	useEffect(()=> {
		// console.log(dataByUnity)
	},[dataByUnity])

	return (

		<div id='metricas' className='pageContainer'>
			<h1 className='main_title'>Financeiro</h1>
			<div className='valuesContainer'>
					<section className='section'>
							<h2 className='sectionTitle'>
								Métricas do {period==='1'?'Mês':period==='3'?'Trimestre':period==='12'?'Ano':'Intervalo'}
							</h2>
						<div className='topLine'>
							{/* <select value={period} onChange={(e) => setPeriod(e.target.value)} className='periodo' name="dateSelect" id="dateSelect">
								<option value='0' disabled hidden  >Escolha uma data</option>
								<option value="1">Mês</option>
								<option value="3">Trimestre</option>
								<option value="12">Ano</option>
								<option value="-1">Escolher Outro Intervalo</option>
							</select> */}
							<div className='datapickerDiv'>
								<DatePicker period={period} 
								getMetricsByMonth={getMetrics}
								getMetricsByQuarter={getMetrics}
								getMetricsByYear={getMetrics}
								getMetricsByRange={getMetrics}
								/>
							</div>
							{/* <Filter showUda={showUda} showUdf={showUdf} showEscritorio={showEscritorio} showTotal={showTotal} 
							setShowUda={setShowUda} 
							setShowUdf={setShowUdf} 
							setShowEscritorio={setShowEscritorio}
							setShowTotal={setShowTotal} /> */}
						</div>
						<div>
						</div>
						{/* { 
						<div>
							<h1>
								Alerta
							</h1>
						</div>
						} */}
							{dataByUnity?(
								<>
									{dataByUnity.reverse().map((unity, index) => (<MetricsTable showUda={showUda} showUdf={showUdf} showEscritorio={showEscritorio} isUnity period={period} key={index} values={unity} />))}
								</>
								
							):(
								<table id='metricsTable' className=''>							
									<tbody>
										<tr >
											<th  className='fullWidthRow'>Escolha um Intervalo </th>
										</tr>
									</tbody>
								</table>
							)}
							
					</section>
			</div>
		</div>
	)
}
export default Metricas
