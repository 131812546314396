import { useState, useEffect, useRef } from "react"
import styles from "./gpt.module.css"
import style from "./styles.css"
import parse from 'html-react-parser';
import NewMessage from "../clients/client/chat/openedChat/newMessage";
import { get_message, post_message } from "../../services/api_gpt";

function ChatLoad() {
    return (
        <div class="center">
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
            </div>
    )
}

function LicitaGPT () {
    const [isLoading, setIsLoading] = useState(false)
    const [messageHistory, setMessageHistory] = useState([
        {
            content: "Olá! Sou o LicitaGPT - Pesquisa e estou aqui para responder suas perguntas sobre licitações",
            type: 0
        }
    ])
    const [newMessage, setNewMessage] = useState('')

    const [selectedGPT, setSeletedGPT] = useState('asst_297ghMUNKocgnZW6mYzOlrNX')

    const scroll = () => {
        const element = document.getElementById('scroll-bottom');
        if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
        }
      };
    async function send_message(e) {
        e.preventDefault();
        if (newMessage.trim().length <= 1 || isLoading) {
            return
        }
        setMessageHistory((messageHistory) => [...messageHistory,
            {
            content: newMessage,
            type: 1
        }])
        setIsLoading(true)
        scroll()
        try{
            const backup_message = newMessage
            setNewMessage('')
            const response = await post_message({data:{
                content: newMessage,
                thread_id: 1
            }, assistant_id:selectedGPT})
            console.log(response)
            const new_answer = response.content.replaceAll("†source","").replaceAll("†fonte","")
            setMessageHistory((messageHistory) => [...messageHistory,{content: new_answer, type:0}])
        } finally {
            setIsLoading(false)
            console.log('finish')
        }
        scroll()
            // } catch {
        //     setNewMessage(backup_message)
        //     temp_history.pop()
        //     setMessageHistory(temp_history)
        // }

    }

    function changeGPT(name, assistantd_id) {
        if (isLoading) {
            return
        }
        setSeletedGPT(assistantd_id)
        console.log(assistantd_id)
        setNewMessage('')
        setMessageHistory([{
            content: `Olá! Sou o ${name} e estou aqui para responder suas perguntas sobre licitações`,
            type: 0
        }])
    }

    const gpt_list = [
        {
            name: 'LicitaGPT - Pesquisa',
            assistant_id: 'asst_297ghMUNKocgnZW6mYzOlrNX'
        },
        {
            name: 'LicitaGPT - Planejamento',
            assistant_id: 'asst_MAWinU7nRzMDLeraMbzaTPvz'
        },
        {
            name: 'LicitaGPT - Correios',
            assistant_id: 'asst_lFk9WWiW2yNzlsyjXKAvWlip'
        }
    ]

    return (
        <div className={styles.gptContainer}>
            <h1>schief.ai & Zênite Fácil</h1>
                <div className={styles.gptNavContainer}>
                    {gpt_list.map((gpt) => (
                        <div className={styles.gptCard + ` ${selectedGPT == gpt.assistant_id ? styles.selected : ''}`} onClick={() => changeGPT(gpt.name, gpt.assistant_id)}>
                            {gpt.name}
                        </div>
                    ))}
                </div>
                <div className={styles.chatContainer}>
                    {messageHistory.map((msg) => (
                        <div className={styles.msgContainer  + ` ${msg.type == 0 ? styles.ai : ''}`}>
                            {parse(msg.content)}
                        </div>
                    ))}
                    <div id="scroll-bottom"/>
                    {isLoading && <ChatLoad/>}
                </div>
                    <form onSubmit={send_message}>
                        <textarea className={styles.msgInputContainer} value={newMessage} onChange={(e) => setNewMessage(e.target.value)}/>
                        <button className={styles.msgSubmitButton}>enviar</button>
                    </form>
        </div>
    )
}

export default LicitaGPT