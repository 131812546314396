const EmptyContainer = () => {
    return(
        <div id="fullinfo">
            <div className="card emptyCard">
                <h3>Selecione um cliente</h3>
            </div>
        </div>
    )
}

export default EmptyContainer