import { useEffect, useRef, useState} from 'react'
import { createCompany, createCustomer, getAllTeams } from '../../../../services/api'
import './styles.css'

const CreateClient = ({showContent, setShowContent}) => {
    const [type,setType] = useState('0')
    const [disabled, setDisabled] = useState(true)
    const nameRef = useRef()
    const emailRef = useRef()
    const idRef = useRef()
    const [teams,setTeams] = useState([])
    const companyRef = useRef()
    const cnpjRef = useRef()

    useEffect(() => {
        (async() => {
                const data = await getAllTeams()
                setTeams(data)
        })()
    },[])
    async function handleFormSubmit  (e){
        e.preventDefault()
           e.target.disabled = true
           const checkboxValues = Array.from(document.querySelectorAll('.checkboxTeams'))
           const checkboxArray = []
           checkboxValues.map((check) => {
               if (check.checked){
                   checkboxArray.push(check.value)
               }
           } )
   
           if (nameRef.current.value === '' ||
               emailRef.current.value === '' ||
               idRef.current.value === '' 
               ){
                   e.target.disabled = false
                   return
               }
           const customer = {
               name: nameRef.current.value,
               type: type,
               email: emailRef.current.value,
               id: idRef.current.value,
               teams: checkboxArray
           }
           if(type==='1'){
               if (
                   companyRef.current.value === '' ||
                   cnpjRef.current.value === '' 
                   ){
                       e.target.disabled = false
                       return
                   }
               const company = {
                   name: companyRef.current.value,
                   cnpj: cnpjRef.current.value
               }
               const createdCompany = await createCompany(company)
               customer.company_id = createdCompany.id
               const createdCustomer = await createCustomer(customer)
               e.target.disabled = false
               return
           }
           const data =await createCustomer(customer)
           e.target.disabled = false
           return
    }
    if(showContent){

        return(
            <div id='Container'>
                <div onClick={() => setShowContent(false)} className='background'><br/></div>
                <form id='createClientForm'>
                    <h3 className='formTitle'>Criar novo cliente</h3>
                    <div className="formField">
                        <label className="formLabel">ID do CRM:</label>
                        <input required ref={idRef} className="formText" type='number'/>
                    </div>
                    <div className="formField">
                        <label className="formLabel">Nome:</label>
                        <input required ref={nameRef} className="formText" type='text'/>
                    </div>
                    <div className="formField">
                        <label className="formLabel">Email: </label>
                        <input required ref={emailRef} className="formText" type='email'/>
                    </div>
                    <br/>
                        <label className="formLabel">Equipes: </label>
                    <div className='checkboxContainer'>
                            {teams?.map((team, i) => (
                                <div key={i} className='checkboxField'>
                                    <label className='formLabel checkboxLabel' htmlFor={team.name}>{team.name}</label>
                                    <input className='checkboxTeams' type='checkbox' id={team.name} value={team.slug}/>
                                </div>
                            ))}
                    </div>
                    <div className="formField">
                        <label className="formLabel">Tipo:</label>
                        <div>
                            <input defaultChecked onChange={(e) => setType(e.target.value)} className="formText" id='0' type='radio' value='0' name='type'/>
                            <label className="formLabel" htmlFor='0'>Física</label>
                        </div>
                        <div>
                            <input onChange={(e) => setType(e.target.value)} className="formText" id='1' type='radio' value='1' name='type'/>
                            <label  className="formLabel" htmlFor='1'>Jurídica</label>
                        </div>
                     </div>
                    {type==='1'?(
                        <>
                            <div className="formField">
                                <label className="formLabel">Nome da Empresa</label>
                                <input required ref={companyRef} className="formText" type='text'/>
                            </div>    
                            <div className="formField">
                                <label className="formLabel">CNPJ</label>
                                <input required ref={cnpjRef} className="formText" type='number'/>
                            </div>    
                        </>
                    ):('')}
        
                    <input className='btnSubmit' onClick={handleFormSubmit} type='submit'/>
        
                </form>
    
    
            </div>
        )
    }
}
export default CreateClient