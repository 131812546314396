import axios from 'axios'
import { getTokens } from './getTokens'
const BASE_URL = 'https://api.schiefler.adv.br/portal/chat'

let auth = getTokens()

const chat_api = axios.create({
    baseURL: BASE_URL,
    headers: { Authorization: `Bearer ${auth?.access_token}` }
})

chat_api.interceptors.request.use(
    config => {
        if (!config.headers['Authorization'] || !config.headers['Authorization'] === '') {
            auth = getTokens()
            config.headers['Authorization'] = `Bearer ${auth?.access_token}`;
        }
        return config;
    }, (error) => Promise.reject(error)
)

export const getPrevMessages = async ({ room_id, currentPage }) => {
    return (await chat_api.get(`/rooms/${room_id}/messages?skip=${currentPage * 15}`)).data
}