import styles from './promptSelector.module.css'
import { prompts } from "../form/prompts"

const PromptList = ({setSelectedPrompt}) => {

    return(
        <div className={styles.container}>
            <h2 className={styles.title}>Escolha o prompt: </h2>
            <ul className={styles.list}>
                {prompts?.map((item, index) => (
                    <li onClick={() => setSelectedPrompt(item)} className={styles.listItem} key={item.name+index}>{item.name}</li>
                    ) )}
            </ul>
        </div>
    )
}

export default PromptList