import { useState, useEffect, useContext } from "react"
import LoadContainer from '../../components/loading'
import {getAllCustomers } from "../../services/api"
import { AuthContext } from '../../contexts/auth'
import Client from "./client"
import './styles.css'
import FullInfo from "./client/fullInfo"
import EmptyContainer from "./client/fullInfo/empty"
import CreateClient from "./client/createClient"
import ArrowBackToHome from "../../components/arrow-back"

const Clients = () => {
    const [clients, setClients] = useState()
    const [loading, setLoading] = useState (false)
    const [showContent,setShowContent] = useState(false)
    const [selectedClient, setSelectedClient] = useState(null)
    
    const selectClient = (client) => {
        if (selectedClient===client){
            setSelectedClient(null)
        }else{
            setSelectedClient(client)
        }
    }

    useEffect(() => {
        (async() => {
            setLoading(true)
            const {data} = await getAllCustomers()
            setClients(data)
            setLoading(false)
        })()
    }, [])
    
    return (
        <div id="clients">
            {loading?<LoadContainer/>:''}
            <ArrowBackToHome/>
            <div className="rowContainer">
                <CreateClient showContent={showContent} setShowContent={setShowContent} />
                <ul className="list">
                    <div className="listTitleRow">
                        <h3 className="title">Clientes</h3>
                        <span onClick={() => setShowContent(prev => !prev)} className="addClient">+
                            <span className="helptext">{`Adicionar Novo Cliente`}</span>
                        </span>
                    </div>
                    {clients?.map((client, index) => (
                        <div key={index} onClick={() =>selectClient(client)}>
                            <Client key={index} index={index} client={client} />
                        </div>
                    ))}
                </ul>
                {selectedClient?(<FullInfo client={selectedClient} />):(<EmptyContainer/>)}
            </div>
        </div>
    )
}

export default Clients