const Client = (props) => {
    const {client, index} = props

    return(
        <li key={index} className='clientListItem' 
        >
            <div className="field">
                <span className="mainName">{client?.name}</span>
            </div>
        </li>
    )
}

export default Client