import { useState } from "react"
import PostForm from "../form/form"
import PromptList from "./promptList"
import styles from './promptSelector.module.css'

const PromptSelector = ({setGeneratedText}) => {
    const [selectedPrompt,setSelectedPrompt] = useState(null)
    const [showPrompt, setShowPrompt] = useState(false)
    
    const handleChangePrompt = (e) => {
        const new_prompt = {...selectedPrompt}
        new_prompt.prompt = e.target.value

        setSelectedPrompt(new_prompt)
    }
    return(
        <>
            {selectedPrompt===null && <PromptList setSelectedPrompt={setSelectedPrompt}/>}
            {selectedPrompt !== null && (
                <>
                <span onClick={() => setSelectedPrompt(null)} className="material-symbols-outlined arrowBack">
                    keyboard_backspace
                </span>
                <p className={styles.prompt}>
                    <span className={styles.subtitle}>
                        Prompt Escolhido: 
                        <span> {selectedPrompt.name}</span>
                        <span  onClick={() => setShowPrompt(prev => !prev)}  className={`material-symbols-outlined ${styles.visibleIcon}`}>{showPrompt?'visibility_off':'visibility'}</span>
                    </span>
                    {showPrompt && <textarea onChange={(e) => handleChangePrompt(e)}>{selectedPrompt.prompt}</textarea>}
                </p>
                <PostForm selectedPrompt={selectedPrompt} setGeneratedText={setGeneratedText}/>
            </>    
            )
        }
        </>
    )
}

export default PromptSelector